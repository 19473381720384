.instructor-panel, .schedule-panel{
 padding-bottom: 10vh;
  p {
    font-weight: bold;
 }
 h4{
    color: var(--dark);
    font-weight: bolder;
    margin-top: 5vh;
 }
}