.getto-splash-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  > div {
    margin-left: calc(50% - 50px);
    margin-top: 30vh;
  }

}
