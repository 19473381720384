.getto-contact-section {
    margin-top: 7vh;
.row{
        margin-top: 2vh;
        .col{
            margin-top: 1vh;
        }
}
 h3  {font-weight: bold;}

     @media screen and (min-width: 760px) {

         &-logo-column{
            margin-left: 100px;
        }
    }
         @media screen and (max-width: 760px) {

         &-logo-column{
            margin-top: 50px;
        }
    }

 &-adres-sub{
    font-size: 13px;
 }


}