.getto-prices-section {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &-button {
    background-color: var(--primary);
    border: 1px solid #303030;
    color: #303030;
    font-weight: bolder;
    font-family: inherit;
  }
  &-button:hover {
    background-color: #303030;
    color: var(--primary);
  }

  .dark-color-header {
    color: var(--dark);
  }

  .row {
    padding: 15px;
    max-width: 100vw;
    align-items: center;
    
    .col {
      text-align: center;

      &[data-visible='true'] {
        -webkit-animation: fade-in 1.15s ease-in forwards;
        animation: fade-in 1.15s ease-in forwards;
      }
      svg {
        min-width: 350px;
        width: 33vw;
        border-radius: 39px;
        padding: 10px;
        border: 3px solid black;
        -webkit-animation: fade-in 0.75s ease-in forwards;
        animation: fade-in 0.75s ease-in forwards;
      }
      > button{
        margin: auto;
        padding: 5px;
        margin-top: 3px;
      }
    }
    > img {
      border-radius: 3px;
      padding-left: 0%;
      padding-right: 0%;
      height: auto;
      max-width: 60%;
      margin-left: 20%;

    @media screen and (max-width: 760px) {
      border-radius: 3px;
      padding-left: 0%;
      padding-right: 0%;
      height: auto;
      max-width: 98%;
      margin-left: 1%;
    }
    }


  }

  h3 {
    justify-content: center;
    margin-top: 5vh;
    margin-bottom: 2vh;
    font-weight: bolder;
    color: var(--primary);
    text-align: center;
  }
}

.dark-background {
  color: #ffffff;
  background-color: #303030;

  position: absolute;
  top: 0;
  left: 0;
  z-index: -100;
  margin-top: calc(var(--from-top-navbar-margin) - 50px);
  height: 42vh;
  width: 100%;
}

.loading-image {
  border: none;
}
