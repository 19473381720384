@import '../../assets/scss/fullscreen.scss';

.photo-modal {
  @include fullscreen();

  left: 0;
  top: 0;
  position: fixed;
  z-index: 12;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: backdrop-filter 0.5s;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);

  &-backdrop {
    overflow-y: hidden;
    z-index: 16;
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    opacity: 0.9;
    background-color: #ffffff;
  }

  &-container {
    position: relative;
    z-index: 17;
    overflow-y: hidden;
    padding: 0px;
    // background-color: #ffffff;
    padding: 20px;

    > .modal-content {
      border: 2px solid var(--primary);
      max-width: 80vw;
      max-height: 78vh;
      height: auto;
      overflow: scroll;

      > .image-container {
        max-width: 100%;
        height: 100%;
        max-height: inherit;
        overflow: hidden;
        > img {
          max-width: 100%;
          height: auto;
          max-height: inherit;
        }
      }
    }
  }
}
