.getto-news-section {
  margin-top: 10vh;
      h3 {
    justify-content: center;
    margin-top: 5vh;
    margin-bottom: 2vh;
    font-weight: bolder;
    color: black;
    text-align: center;
  }

}