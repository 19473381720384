.close-button-container {
    position: relative;
    vertical-align: top;
    background: none;
    pointer-events: none;
    border: none;
    z-index: 20;
    margin-left: 97%;
   > svg{
       height: 2.5vh;
       width: 2.5vw;
       pointer-events: all;
   }
   > svg:hover {
      opacity: 0.6;
  }



}