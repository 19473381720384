

.getto-main-section {
  width: 100vw;
  height: 100vh;
}

.getto-sub-section{
  width: 80%;
  min-height: 100%;
  height: fit-content;
  margin-left: 10%;
  
}


@media (prefers-reduced-motion: no-preference) {
  .getto-logo {
    animation: App-logo-spin infinite 5s linear;
  }
}





