.footer_content {
  background-color: #303030;
   margin-top: 7%;
  min-height: 20vh;
  padding-top: 11px;
  left: 0;
  margin-left: 0;
  justify-content: space-between;
  position: relative;
  bottom: 0;
  right: 0;

  width: 100%;
  max-width: 100vw;

  .row {
    margin: 0;
    margin-left: 10%;
    width: 80%;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    justify-content: space-between;
    vertical-align: baseline;
    color: white;
    padding-bottom: 10px;
    font-size: 1rem;
    font-weight: bold;
    max-height: 100vw;
    .column {
      padding-right: 10px;
      flex-grow: 1;
      max-width: 25%;
      > h4 {
        text-align: left;
        padding-bottom: 5px;
        width: fit-content;
        border-bottom: 1px solid white;
      }


      @media only screen and (max-device-width: 750px) {
        h4 {
          font-size: 11px;
        }

        li {
          font-size: 9px;
          width: 130px;
        }

        p {
          font-size: 11px;
          padding-right: 10px;
        }
      }

      > ul {
        text-align: left;
        padding-left: 0;

        > li {
          font-family: inherit;
          list-style-type: none;
          text-align: left;
          font-weight: normal;
          padding-bottom: 1px;
          > .social1 {
            margin-right: 13px;
            margin-top: 2px;
            > div {
              > svg {
                background-color: transparent;
              }
            }
          }
          > .social2 {
            margin-top: 2px;
            > div {
              > svg {
                background-color: transparent;
              }
            }
          }
        }
        @media only screen and (max-device-width: 900px) {
          .socials {
            max-width: 10vw;
            .social1 {
              margin-bottom: 10px;
            }
          }
        }
        > p {
          margin-top: 72px;
          font-family: inherit;
        }
      }
    }

    .column:nth-of-type(4) {
      margin: 0;
      padding: 0;
    }
  }
}
