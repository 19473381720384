.splash {
    > div{
        text-align: center;
         > button {
            padding: 10px;
            color: rgba(74, 39, 168, 0.856);
            font-weight: bolder;
        }

        > button:hover {
        background-color: rgba(201, 201, 9, 0.747);
        }

        }
            
}