$bg-color: #e5e5f7;
$secondary-bg-color: #698f5c;
$primary-color: #444cf7;

html,
body {
  width: 100%;
  height: 100%;

  #root {
    width: 100%;
    height: 100%;
  }
}


.AdminApp {
  text-align: center;
  background-color: var(--bg-color);
  height: 100%;

  &-work-correctly{
    margin-top: 50px;
  }

  .signout-button {
    padding: 3px;
    color: rgba(74, 39, 168, 0.856);
    font-weight: bolder;
    margin-bottom: 35px;

  }

  .signout-button:hover {
    background-color: rgba(201, 201, 9, 0.747);
  }

  .panel-button {
      font-size: 1rem;
      width: 17%;
      padding: 10px;
      font-weight: bolder;
      border: grey solid 2px;
      background-color: transparent;
      background-color: #444cf7;
      color: rgb(154, 197, 0);
      box-shadow: 1px 2px rgb(32, 32, 32);
      margin-right: 5px;

    &[data-selected="true"] {
      background-color: rgb(154, 197, 0);
      color: black;
      pointer-events: none;
    }
    .admin-nav {
      padding-top: 30px;
      padding-bottom: 30px;
       h2{
        padding: 10px;
      }
    }
}

.panel-button:hover {
  opacity: 0.7;
  box-shadow: 3px 3px rgb(32, 32, 32);
}


.content {
  position: absolute;
  width: 100%;
  height: 100%;
  padding-bottom: 15%;
}

#logo {
  top: 30px;
  padding: 0;
  position: relative;
  line-height: 60px;
  margin-left: calc(30% - 1vw);
  color: black;
  width: 40%;
  letter-spacing: 2px;
  text-shadow: 0 0 5px;
  margin-bottom: 10px;
  > h3 {
    margin-top: -30%;
  }
  pointer-events: none;
}
}