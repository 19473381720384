.getto-home-section {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .row {
    padding: 15px;
    max-width: 100vw;
    .col {
      &[data-visible='true'] {
        -webkit-animation: fade-in 1.15s ease-in forwards;
        animation: fade-in 1.15s ease-in forwards;
      }
      svg {
        min-width: 350px;
        width: 33vw;
        border-radius: 39px;
        padding: 10px;
        border: 3px solid black;
        -webkit-animation: fade-in 0.75s ease-in forwards;
        animation: fade-in 0.75s ease-in forwards;
      }
    }
  }

  h3 {
    justify-content: center;
    margin-top: 5vh;
    margin-bottom: 2vh;
    font-weight: bolder;
    color: var(--primary);
    text-align: center;
  }

  h5 {
    justify-content: center;
    color: var(--primary);
    text-align: center;
    width: 90%;
    margin-left: 5%;
  }

  &-header{
    font-size: x-large;
  }

  .dark-color-header {
    color: var(--dark);
  }

  &-korpow-button {
    background-color: var(--primary);
    width: 30%;
    margin-left: 35%;
    border: 1px solid #303030;
    color: #303030;
    font-weight: bolder;
    font-family: inherit;
  }

  &-korpow-button:hover {
    background-color: #303030;
    color: var(--primary);
  }

  &-photos {
    &-single {
      margin-left: 10%;
      width: 86%;
      margin-bottom: 5%;
      > img {
        border-radius: 9px;
        max-width: 100%;
      }
      > img:hover {
        border-radius: 3px;
        border: 3px solid var(--primary);
        max-width: calc(100% - 6px);
      }
    }
  }
  @media screen and (max-width: 760px) {
  &-map-column {
    width: 100%;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    justify-content: center;
  }
}
}

.dark-background {
  color: #ffffff;
  background-color: #303030;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  margin-top: calc(var(--from-top-navbar-margin) - 50px);
  height: 42vh;
  width: 100%;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0.8;
  }
  40% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0.8;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
