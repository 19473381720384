.nav-bar {
     background-color: var(--primary);
     border-bottom: 1px solid  #c9deea;
     position: fixed;
     width: 100%;
     top: 0;
     z-index: 10;
    &-container {
        margin: 0;
        width:84%;
        margin-top: 8px;
        letter-spacing: -1px;
        align-items: left;

        .getto-nav{
        &-link {
            font-size: calc(0.40rem + 9px);
            font-weight: bolder;
            margin: 2vw;
            white-space: nowrap;
            width: fit-content;
            max-width: 91%;
        }
        &-dropdown-item{
            background-color: white;
            color: black;
        }
    }

        &-logo {
            margin-left: 5%;

        }


    }
}
