.school-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 5vh;

  h3 {
    justify-content: center;
    margin-top: 5vh;
    font-weight: bolder;
    color: var(--primary);
    text-align: center;
  }
  h2 {
    justify-content: left;
    margin-bottom: calc(3.5vh + 10px);
    font-weight: bolder;
    color: var(--dark);
    text-align: center;
  }
  &-group-button {
    margin-top: 5vh;
    background-color: var(--primary);
    width: 144px;
    margin-left: calc(50% - 72px);
    font-size: calc(5px + 0.55rem);
    border: 1px solid var(--dark);
    color: var(--dark);
    font-weight: bolder;
    font-family: inherit;
    -webkit-animation: opacity-animation 9.15s ease-in infinite;
  }
  &-group-button:hover {
    background-color: #303030;
    color: var(--primary);
  }
  img {
    height: auto;
    max-width: 100%;
  }
}

.group {
  &-row {
    margin-top: calc(5px + 3vh);
  }
  @media screen and (min-width: 760px) {
  &-row {
    margin-top: calc(35px + 8vh);
  }
}
  &-contact-column {
    padding-bottom: 20px;
    @media screen and (min-width: 760px) {
      width: 30%;
      padding-top: 10%;
    }
    padding-bottom: 15px;
    h6{
      text-align: left;
      font-size: 14px;
    }

  }

  &-image-column {
    border: 1px solid var(--primary);
    border-radius: 3px;
    @media screen and (min-width: 760px) {
    width: 60%;
    }
    margin-left: 5%;
    color: white;
    background-color: #000000;
    padding: 5px;
    height: fit-content;
    > h3 {
      padding: 0;
      margin: 8px;
      font-weight: bolder;
    }
  }
}

.loading-row {
  justify-content: center;
}

@-webkit-keyframes opacity-animation {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0.8;
  }
  40% {
    opacity: 0.6;
  }
  60% {
    opacity: 0.4;
  }
  80% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@keyframes opacity-animation {
  0% {
    opacity: 1;
  }
  80% {
    background-color: var(--primary);
    color: var(--dark);
  }
  90% {
    background-color: var(--dark);
    color: white;
  }
}
