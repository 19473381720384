@mixin fullscreen {
    width: 100vw;
    height: 100%;

    @media not all and (min-resolution: 0.001dpcm){
        @supports (-webkit-appearance: none) and (stroke-color: transparent) and 
            (-webkit-touch-callout: none) {
                width: -webkit-fill-available;
                height: -webkit-fill-available;
        }
    }
}