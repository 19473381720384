.home-carousel {
  display: block;
  width: 74%;
  max-width: 90%;
  margin-left: 13%;
  border-radius: 99px;

  img {
    width: unset;
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  h5 {
    color: black;
  }
}
